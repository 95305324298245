<template>
  <div>
    <b-button
      v-ripple.400="'rgba(40, 199, 111, 0.15)'"
      variant="outline-primary"
      class="btn-icon"
      size="lg"
      v-b-toggle.sidebar-course
    >
      <feather-icon icon="ListIcon" />
    </b-button>

    <b-sidebar
      width="350px"
      id="sidebar-course"
      shadow
      bg-variant="white"
      backdrop
      v-model="show"
      class="sections-sidebar"
    >
      <b-form-group label-for="filterInput" class="mt-5 mb-2 p-50">
        <b-input-group size="sm">
          <b-form-input
            id="filterInput"
            v-model="filter"
            type="search"
            placeholder="Type to Search"
          />
          <b-input-group-append>
            <b-button :disabled="!filter" @click="filter = ''">
              Clear
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
      <app-timeline class="">
        <app-timeline-item
          :variant="index % 2 == 1 ? 'secondary gradient' : 'primary gradient'"
          v-for="(section, index) in sections"
          :key="index"
          class="p-25 p-md-50 pt-md-5 w-100"
          icon="FileTextIcon"
        >
          <div
            class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
          >
            <span
              v-if="section"
              class="mr-50 ml-50 text-truncate d-inline-block item-description card-text item-description"
              v-b-toggle="'report-list-with-icon' + index"
            >
              {{ section.title }}
            </span>
          </div>
          <!-- <p>Click the button below to read financial reports</p> -->

          <b-collapse
            :visible="index == currentSectionOrder || filter != ''"
            :id="'report-list-with-icon' + index"
          >
            <b-list-group flush class="mt-1 w-100">
              <b-list-group-item
                v-for="(item, index) in section.items"
                :id="item.id"
                :key="item.id"
                @click="changeSelectedItem(item, section)"
                class="d-flex justify-content-between align-items-center bg-transparent"
              >
                <b-row class="w-100">
                  <b-col
                    v-if="item.quiz"
                    md="12"
                    class="p-0"
                    :class="
                      item.quiz.title.includes(filter) && filter != ''
                        ? 'active-search'
                        : ''
                    "
                  >
                    <b-row class="m-0">
                      <span class="text-dark m-25">{{ index + 1 }} -</span>
                      <span class="text-danger m-25">
                        <feather-icon icon="CheckSquareIcon" />
                      </span>
                      <span
                        v-if="item.quiz"
                        style="cursor: pointer"
                        class="m-25 text-truncate d-inline-block item-description card-text item-description"
                        >{{ item.quiz.title }}</span
                      >
                    </b-row>
                  </b-col>
                  <b-col
                    v-else-if="item.lesson != null"
                    md="12"
                    :class="
                      item.lesson.title.includes(filter) && filter != ''
                        ? 'active-search'
                        : ''
                    "
                    class="p-0"
                  >
                    <b-row class="m-0">
                      <span class="text-dark m-25">{{ index + 1 }} -</span>
                      <span class="text-success m-25">
                        <feather-icon icon="FileTextIcon" class="m-25" />
                      </span>
                      <!-- <b-avatar rounded :src="item.lesson.imageCover" /> -->
                      <span
                        v-if="item.lesson"
                        class="m-25"
                        style="cursor: pointer"
                      >
                        {{ item.lesson.title }}
                      </span>
                    </b-row>
                  </b-col>
                </b-row>
              </b-list-group-item>
            </b-list-group>
          </b-collapse>
        </app-timeline-item>
      </app-timeline>
    </b-sidebar>
  </div>
</template>

<script>
import BCardCode from "@core/components/b-card-code";
import {
  BButton,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BForm,
  BSidebar,
  VBToggle,
  BCardText,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import SidebarContent from "@/views/courses pages/components/SidebarContent.vue";
import sectionsApi from "@/apiServices/sectionsApi";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
    BCardCode,
    BButton,
    BSidebar,
    SidebarContent,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardText,
  },
  props: ["sections", "currentSectionOrder"],
  directives: {
    "b-toggle": VBToggle,
    Ripple,
  },
  data() {
    return {
      filter: "",
      show: false,
    };
  },
  methods: {
    changeSelectedItem(item, currentSection) {
      this.$emit("changeSelectedItem", item, currentSection);
      this.show = false;
    },
  },
};
</script>
<style>
.b-sidebar-body::-webkit-scrollbar {
  width: 9px;
}

/* Track */
.b-sidebar-body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(253, 247, 247, 0.3);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

/* Handle */
.b-sidebar-body::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(0, 179, 255, 0.614);
  -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.5);
}
.active-search {
  background-color: #ffe4ca;
  /* padding: 5px !important; */
  color: #956639;
}
</style>
