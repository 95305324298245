<template>
  <section v-if="course != null &&
    userData &&
    userData.courses.find((e) => e.course === course._id) &&
    !isExpired
    ">
    <b-card-code style="min-height: 80vh" :title="course.title" no-body>
      <b-card-body>
        <b-row class="w-100" align-h="between">
          <progressSidebar :currentSectionOrder="currentSectionOrder" :sections="course.sections" class="mr-md-5 ml-md-5"
            @changeSelectedItem="changeSelectedItem" />
          <h2 class="text-success">{{ currentSection.title }}</h2>
          <h6>
            you have finshed
            <strong class="text-success">
              {{ Math.ceil(finishingPercintage) }}</strong>
            % of the Course
          </h6>
        </b-row>

        <b-row align-h="center" style="min-height: 58vh" class="" v-if="selectedItem">
          <b-col md="11" v-if="selectedItem.quiz" class="p-0">
            <b-row class="m-0">
              <span class="text-danger m-25 ml-0 mr-0">
                <feather-icon icon="CheckSquareIcon" />
              </span>
              <h1 class="text-truncate d-inline-block item-description card-text item-description">
                {{ selectedItem.quiz.title }}
              </h1>
              <questions :userData="userData" :quizData1="selectedItem.quiz" />
            </b-row>
          </b-col>
          <b-col v-else md="9">
            <b-row class="">
              <h4 class="text-success m-25 ml-0 mr-0">
                <feather-icon icon="FileTextIcon" class="m-25" />
              </h4>
              <h4 class="m-25">
                {{ selectedItem.lesson.title }}
              </h4>
            </b-row>
            <b-row class="w-100">
              <div class="w-100 m-50 description-content" v-html="selectedItem.lesson.description"></div>
            </b-row>
          </b-col>
        </b-row>
        <b-row align-h="between" class="m-0 p-2" v-if="selectedItem">
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="secondary" @click="geToPreviosItem"
            :disabled="currentItemIndex == 0">
            Beck
          </b-button>

          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary"
            v-if="currentItemIndex + 1 != currentSection.items.length" @click="geToNextItem">
            <feather-icon icon="CheckIcon" class="mr-50" />
            Next
          </b-button>
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success"
            v-else-if="currentSectionIndex + 1 != course.sections.length" @click="geToNextSection">
            <feather-icon icon="CheckIcon" class="mr-50" />
            Go To Next Section
          </b-button>
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success" v-else @click="CompleteCourse">
            <feather-icon icon="CheckIcon" class="mr-50" />
            Complete the course
          </b-button>
        </b-row>
      </b-card-body>
    </b-card-code>
  </section>
  <b-alert variant="primary" show v-else-if="course != null && isExpired">
    <h4 class="alert-heading">Your license is Expired</h4>
    <div class="alert-body">
      <courseButtons class="w-100 mt-25" :course="course" />
    </div>
  </b-alert>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import {
  BCollapse,
  BRow,
  BCol,
  BCard,
  BCardBody,
  BTableLite,
  BCardText,
  BButton,
  BAlert,
  BLink,
  VBToggle,
} from "bootstrap-vue";
import Logo from "@core/layouts/components/Logo.vue";
import Ripple from "vue-ripple-directive";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import "swiper/css/swiper.css";

import coursesApi from "@/apiServices/coursesApi";
import userApi from "@/apiServices/userApi";
import courseButtons from "@/views/courses pages/components/coursebuttons.vue";
import progressSidebar from "@/views/courses pages/components/progressSidebar.vue";
import questions from "@/views/courses pages/components/questions.vue";
import sectionCard from "@/views/courses pages/components/sectionCard.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  components: {
    questions,
    Swiper,
    SwiperSlide,
    BCardCode,
    sectionCard,
    BCardBody,
    progressSidebar,
    courseButtons,
    BRow,
    BCol,
    BCard,
    ToastificationContent,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,

    Logo,
    // InvoiceSidebarAddPayment,
    // InvoiceSidebarSendInvoice,
  },
  data() {
    return {
      refreshInterval: null, // Define refreshInterval here

      currentSection: null,
      swiperData: [
        { icon: "GithubIcon", text: "Getting Started" },
        { icon: "FacebookIcon", text: "Pricing & Plans" },
        { icon: "TwitterIcon", text: "Sales Question" },
        { icon: "InstagramIcon", text: "Usage Guides" },
        { icon: "GitlabIcon", text: "General Guide" },
      ],
      swiperOptions: {
        centeredSlides: true,
        spaceBetween: 10,
        navigation: {
          type: "progressbar",

          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      course: null,
      selectedItem: null,
      userData: JSON.parse(localStorage.getItem("user")),
    };
  },
  computed: {
    currentSectionIndex() {
      let currentSectionIndex1 = this.course.sections.findIndex(
        (p) => p._id == this.currentSection._id
      );
      return currentSectionIndex1;
    },
    currentSectionOrder() {
      let tempCourse = this.userData?.courses.find(
        (e) => e.course == this.course._id
      );
      // console.log(tempCourse.sectionOrder / this.course?.sections?.length);
      if (tempCourse.sectionOrder == this.course?.sections?.length) {
        return tempCourse.sectionOrder - 1;
      } else {
        return tempCourse.sectionOrder;
      }
    },
    isExpired() {
      let students = this.course.students;
      console.log(students + "555555555");
      let StudentExpirationDate = students.find(
        (e) => e.student?._id == this.userData._id
      );
      console.log(StudentExpirationDate?.expairationDate);
      console.log(
        new Date() > new Date(StudentExpirationDate?.expairationDate)
      );
      return new Date() > new Date(StudentExpirationDate?.expairationDate);
    },
    currentItemIndex() {
      let currentItemIndex1 = this.currentSection.items.findIndex(
        (p) => p._id == this.selectedItem._id
      );
      console.log(currentItemIndex1);
      return currentItemIndex1;
    },
    finishingPercintage() {
      let tempCourse = this.userData.courses.find(
        (e) => e.course == this.course._id
      );

      let Percintage =
        (tempCourse.sectionOrder / this.course?.sections?.length) * 100;
      return Percintage;
    },
  },
  created() {
    this.getCourse();
  },
  mounted() {
    // Set interval to refresh the course data every 5 minutes (300000 milliseconds)
    this.refreshInterval = setInterval(() => {
      window.location.reload(); // Reload the page
    }, 3600000); // 5 minutes
  },
  beforeDestroy() {
    // Clear the interval when the component is destroyed to prevent memory leaks
    clearInterval(this.refreshInterval);
  },
  methods: {
    CompleteCourse() {
      userApi
        .updateMyCourse({
          course: this.course._id,
          sectionOrder: this.currentSectionIndex + 1,
        })
        .then((response) => {
          if (response.data) {
            console.log(response.data);
            localStorage.setItem("user", JSON.stringify(response.data.data));
            this.userData = JSON.parse(localStorage.getItem("user"));
            this.$toast({
              component: ToastificationContent,
              props: {
                title:
                  "Congratulation " +
                  this.userData.name +
                  " you have Complete " +
                  this.course.title,
                icon: "EditIcon",
                variant: "success",
              },
            });
          } else {
          }
        });
    },
    changeSelectedItem(item, currentSection) {
      this.currentSection = currentSection;
      this.selectedItem = item;
      console.log(item);
    },

    geToNextSection() {
      userApi
        .updateMyCourse({
          course: this.course._id,
          sectionOrder: this.currentSectionIndex + 1,
        })
        .then((response) => {
          if (response.data) {
            console.log(response.data);
            localStorage.setItem("user", JSON.stringify(response.data.data));
            this.userData = JSON.parse(localStorage.getItem("user"));
            this.currentSection =
              this.course.sections[this.currentSectionIndex + 1];
            this.selectedItem = this.currentSection.items[0];
          } else {
          }
        });
    },
    geToNextItem() {
      this.selectedItem = this.currentSection.items[this.currentItemIndex + 1];
    },
    geToPreviosItem() {
      this.selectedItem = this.currentSection.items[this.currentItemIndex - 1];
    },
    getCourse() {
      coursesApi.getCourse(this.$route.params.id).then((response) => {
        this.courses = [];
        if (response.data) {
          console.log(response.data);
          this.course = response.data?.data;
          this.currentSection = this.course.sections[this.currentSectionOrder];
          this.selectedItem = this.currentSection.items[0];
        } else {
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
.description-content iframe {
  width: 100% !important;
}

.course-section {
  margin-top: -60px;
}

.course-bg {
  height: 350px;
}

@media print {

  // Global Styles
  body {
    background-color: transparent !important;
  }

  nav.header-navbar {
    display: none;
  }

  .main-menu {
    display: none;
  }

  .header-navbar-shadow {
    display: none !important;
  }

  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }

  footer.footer {
    display: none;
  }

  .card {
    background-color: transparent;
    box-shadow: none;
  }

  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            >.col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
