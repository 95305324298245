<template>
  <div>
    <div v-for="(item, index) in sidebarItems" :key="item._id" class="p-1">
      <feather-icon class="align-text-bottom" icon="ListIcon" />
      <b-button
        @click="chosse(item, index)"
        v-if="item.type == 'lesson'"
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        variant="flat-secondary"
        class="p-0"
      >
        {{ item.lesson.title + index }}
      </b-button>
      <b-button
        v-if="item.type == 'quiz'"
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        variant="flat-secondary"
        class="p-0"
      >
        {{ item.quiz.title }}
      </b-button>
    </div>
  </div>
</template>

<script>
import { BAvatar, BListGroup, BListGroupItem, BCardText } from "bootstrap-vue";

export default {
  components: {
    BAvatar,
    BListGroup,
    BListGroupItem,
    BCardText,
  },
  props: ["sidebarItems"],
  created() {},
  methods: {
    chosse(chosse, index) {},
  },
  data() {
    return {
      Activeone: [],
    };
  },
};
</script>
