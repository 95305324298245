<template>
  <div v-if="quizData" class="container-fluid wrapper">
    <b-row v-if="quizData && quizData.questions &&
      quizData.questions.length > 0 &&
      selectedQuestion != null &&
      !quizCompleted
      ">
      <div class="col-12 col-lg-9 form_wrapper">
        <b-row class="d-md-none" align-h="center pb-2">
          <quizTimer :TIME_LIMIT="quizData.timer * 60" />
        </b-row>
        <div class="">
          <div class="question_title">
            <b-row class="w-100 m-0" align-h="center">
              <b-img thumbnail fluid v-if="selectedQuestion.imageCover" style="max-height: 200px"
                :src="selectedQuestion.imageCover" alt="Image 1"></b-img>
            </b-row>

            <h4 class="bg-white rounded-pill position-relative text-white">
              {{ currentQuestionIndex }}- {{ selectedQuestion.title }}
            </h4>
          </div>
        </div>
        <b-row v-if="selectedQuestion.hint">
          <b-card no-body class="w-100 p-0">
            <b-card-header header-tag="header" class="" role="tab">
              <b-button block v-b-toggle="selectedQuestion._id" variant="link">See Question Hint</b-button>
            </b-card-header>
            <b-collapse :id="selectedQuestion._id">
              <b-card-body>
                <b-alert variant="warning" show>
                  <div class="alert-body">
                    <span><strong>{{ selectedQuestion.hint }}</strong>
                    </span>
                  </div>
                </b-alert>
              </b-card-body>
            </b-collapse>
          </b-card>
        </b-row>
        <b-form-checkbox-group v-if="selectedQuestion.type == 'multi_choice'" :id="selectedQuestion._id"
          v-model="SelectedAnswer" name="flavour-2" class="w-100 row m-0">
          <b-col class="m-0 pt-25" v-for="(answer, index) in selectedQuestion.answers" :key="index" md="6" lg="4">
            <b-row class="m-0">
              <b-img thumbnail fluid v-if="answer.imageCover" style="max-height: 200px" :src="answer.imageCover"
                alt="Image 1"></b-img>
              <div class="row m-0 align-items-center w-100 answer-lable rounded-pill">
                <b-form-checkbox class="custom-control-info w-100" :value="answer">
                  <h6 class="w-100 m-0">
                    {{ alphabet[index] }}. {{ answer.title }}
                  </h6>
                </b-form-checkbox>
              </div>

              <b-row v-if="answer.hint" class="w-100 m-0 p-0">
                <b-card no-body class="w-100 p-0">
                  <b-card-header class="w-100 p-0" header-tag="header" role="tab">
                    <b-button block v-b-toggle="answer._id" variant="link">Hint</b-button>
                  </b-card-header>
                  <b-collapse :id="answer._id">
                    <b-card-body>
                      <b-card-text>{{ answer.hint }}</b-card-text>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </b-row>
            </b-row>
          </b-col>
        </b-form-checkbox-group>
        <b-row align-v="center" v-if="selectedQuestion.type == 'single_choice' ||
          selectedQuestion.type == 'true_false'
          ">
          <b-col class="m-0 pt-25" v-for="(answer, index) in selectedQuestion.answers" :key="index" md="6" lg="4">
            <b-row class="m-0">
              <b-img thumbnail fluid v-if="answer.imageCover" style="max-height: 200px" :src="answer.imageCover"
                alt="Image 1"></b-img>
              <div :class="`${answer == SelectedAnswer ? 'answer-lable-active' : ''
                }`" class="row m-0 align-items-center w-100 answer-lable rounded-pill">
                <b-form-radio v-model="SelectedAnswer" :value="answer" :name="selectedQuestion._id"
                  class="custom-control-info w-100">
                  <b-row class="w-100 m-0" align-h="center"> </b-row>

                  <h6 class="w-100 m-0">
                    {{ alphabet[index] }}. {{ answer.title }}
                  </h6>
                </b-form-radio>
              </div>

              <b-row v-if="answer.hint" class="w-100 m-0 p-0">
                <b-card no-body class="w-100 p-0">
                  <b-card-header class="w-100 p-0" header-tag="header" role="tab">
                    <b-button block v-b-toggle="answer._id" variant="link">Hint</b-button>
                  </b-card-header>
                  <b-collapse :id="answer._id">
                    <b-card-body>
                      <b-card-text>{{ answer.hint }}</b-card-text>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </b-row>
            </b-row>
          </b-col>
        </b-row>

        <b-row align-h="center" class="mt-2">
          <b-pagination v-model="currentQuestionIndex" :total-rows="quizData.questions.length" :per-page="1" size="lg"
            disabled align="center" />
        </b-row>
      </div>
      <b-col md="3">
        <b-row class="d-none d-md-block pb-50 w-100 m-0" align-h="center">
          <quizTimer :TIME_LIMIT="quizData.timer * 60" />
        </b-row>
        <b-row align-h="between" class="w-100 m-0">
          <b-img thumbnail fluid class="d-none d-md-block" style="max-height: 390px; height: 266px"
            src="@/assets/images/pages/calendar-illustration.png" alt="Image 1"></b-img>

          <!-- <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="secondary"
            @click="geToPreviosItem"
            class="mt-25"
            :disabled="currentQuestionIndex - 1 == 0"
          >
            Beck
          </b-button> -->
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary"
            v-if="currentQuestionIndex < quizData.questions.length" @click="geToNextItem" class="mt-25 w-100">
            <feather-icon icon="CheckIcon" class="mr-50" />
            Next
          </b-button>
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success" class="mt-25 w-100" @click="compliteQuiz"
            v-else>
            <feather-icon icon="CheckIcon" class="mr-50" />
            Complete the Quiz
          </b-button>
        </b-row>
      </b-col>
    </b-row>
    <b-row v-if="quizCompleted" align-h="center">
      <b-img thumbnail fluid style="max-height: 490px; height: 406px" src="@/assets/images/checkmark.png"
        alt="Image 1"></b-img>
      <b-col sm="12" class="mt-2">
        <div class="sub_title text-center">
          <h1>
            Your received {{ Math.floor(QuezGrad) }} Of {{ quizData.grad }}
          </h1>
        </div>
        <div class="complite-tit pt-1 text-center">
          <h3>Thank you</h3>
        </div>
      </b-col>
      <b-col sm="12" class="mt-3">
        <div class="sub_title text-center"></div>
        <div class="complite-tit pt-1 text-center">
          <h3>See All Questions Answers</h3>
        </div>
      </b-col>
      <b-col sm="12" class="mt-2" v-for="(question, index) in quizData.questions" :key="index">
        <div class="">
          <div class="question_title">
            <b-row class="w-100 m-0" align-h="center">
              <b-img thumbnail fluid v-if="question.questionId.imageCover" style="max-height: 200px"
                :src="question.questionId.imageCover" alt="Image 1"></b-img>
            </b-row>

            <h4 class="bg-white rounded-pill position-relative text-white">
              {{ index + 1 }}- {{ question.questionId.title }}
            </h4>
          </div>
        </div>
        <b-row v-if="question.questionId.hint">
          <b-card no-body class="w-100 p-0">
            <b-card-header header-tag="header" class="" role="tab">
              <b-button block v-b-toggle="question.questionId._id" variant="link">See Question Hint</b-button>
            </b-card-header>
            <b-collapse :id="question.questionId._id">
              <b-card-body>
                <b-alert variant="warning" show>
                  <div class="alert-body">
                    <span><strong>{{ question.questionId.hint }}</strong>
                    </span>
                  </div>
                </b-alert>
              </b-card-body>
            </b-collapse>
          </b-card>
        </b-row>
        <b-form-checkbox-group v-if="question.questionId.type == 'multi_choice'" :id="question.questionId._id"
          v-model="SelectedAnswer" name="flavour-2" class="w-100 row m-0">
          <b-col class="m-0 pt-25" v-for="(answer, index) in question.questionId.answers" :key="index"
            v-if="answer.isCorrect" md="6" lg="4">
            <b-row class="m-0">
              <b-img thumbnail fluid v-if="answer.imageCover" style="max-height: 200px" :src="answer.imageCover"
                alt="Image 1"></b-img>
              <div class="row m-0 align-items-center w-100 answer-lable rounded-pill">
                <b-form-checkbox class="custom-control-info w-100" :value="answer">
                  <h6 class="w-100 m-0">
                    {{ alphabet[index] }}. {{ answer.title }}
                  </h6>
                </b-form-checkbox>
              </div>
              <b-row v-if="answer.hint" class="w-100 m-0 p-0">
                <b-card no-body class="w-100 p-0">
                  <b-card-header class="w-100 p-0" header-tag="header" role="tab">
                    <b-button block v-b-toggle="answer._id" variant="link">Hint</b-button>
                  </b-card-header>
                  <b-collapse :id="answer._id">
                    <b-card-body>
                      <b-card-text>{{ answer.hint }}</b-card-text>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </b-row>
            </b-row>
          </b-col>
        </b-form-checkbox-group>
        <b-row align-v="center" v-if="question.questionId.type == 'single_choice' ||
          question.questionId.type == 'true_false'
          ">
          <b-col class="m-0 pt-25" v-for="(answer, index) in question.questionId.answers" :key="index"
            v-if="answer.isCorrect" md="6" lg="4">
            <b-row class="m-0">
              <b-img thumbnail fluid v-if="answer.imageCover" style="max-height: 200px" :src="answer.imageCover"
                alt="Image 1"></b-img>
              <div :class="`${answer == SelectedAnswer ? 'answer-lable-active' : ''
                }`" class="row m-0 align-items-center w-100 answer-lable rounded-pill">
                <b-form-radio v-model="SelectedAnswer" :value="answer" :name="question._id"
                  class="custom-control-info w-100">
                  <b-row class="w-100 m-0" align-h="center"> </b-row>

                  <h6 class="w-100 m-0">
                    {{ alphabet[index] }}. {{ answer.title }}
                  </h6>
                </b-form-radio>
              </div>

              <b-row v-if="answer.hint" class="w-100 m-0 p-0">
                <b-card no-body class="w-100 p-0">
                  <b-card-header class="w-100 p-0" header-tag="header" role="tab">
                    <b-button block v-b-toggle="answer._id" variant="link">Hint</b-button>
                  </b-card-header>
                  <b-collapse :id="answer._id">
                    <b-card-body>
                      <b-card-text>{{ answer.hint }}</b-card-text>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </b-row>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
      <b-col>
        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success" class="mt-25 w-100" @click="retakeQuiz">
          <feather-icon icon="CheckIcon" class="mr-50" />
          Retake the Quiz
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  bCardCode,
  bCard,
  BCollapse,
  BButton,
  BCol,
  BFormRadioGroup,
  BFormGroup,
  BFormInput,
  BFormCheckboxGroup,
  BFormCheckbox,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import userApi from "@/apiServices/userApi";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import questionsApi from "@/apiServices/questionsApi";
import quizesApi from "@/apiServices/quizesApi";
import quizTimer from "@/views/courses pages/components/quizTimer.vue";
function initialState() {
  return {

    currentQuestionIndex: 1,
    quizCompleted: false,
    alphabet: Array.from("ABCDEFGHIJKLMNOPQRSTUVWXYZ"),
    quizData: null,
    QuezGrad: 0,
    changeTime: false,
    selectedQuestion: undefined,
    isUserAnswerCorrect: undefined,
    message: "",
    SelectedAnswer: undefined,

  }
}
export default {
  components: {
    BFormCheckboxGroup,
    BFormCheckbox,
    BFormRadioGroup,
    quizTimer,
    BRow,
    bCardCode,
    BCollapse,
    BButton,
    bCard,
    AppTimeline,
    AppTimelineItem,
  },
  directives: {
    Ripple,
  },
  name: "Questions",
  props: {
    quizData1: Object,
    userData: Object,
  },
  data() {
    return initialState();
  },
  created() {
    this.getQuiz();
  },
  computed: {
    QuizId() {
      return this.quizData1._id;
    }
  },
  watch: {
    QuizId(newVal, oldVal) {
      if (oldVal != newVal) {
        this.getQuiz();
      }
      this.currentQuestionIndex = 1;
      this.selectedQuestion = this.quizData.questions[this.currentQuestionIndex - 1]?.questionId;
    },
    currentQuestionIndex() {
      this.selectedQuestion = this.quizData.questions[this.currentQuestionIndex - 1]?.questionId;
    },
  },
  methods: {
    async checkUserTakeQuiz() {
      for (const el of this.quizData.students) {
        if (el.studentId === this.userData._id) {
          this.SelectedAnswer = undefined;
          this.QuezGrad = el.grad;
          this.quizCompleted = true;
          break;
        }
      }
      if (!this.quizCompleted) {
        this.selectedQuestion = this.quizData?.questions[this.currentQuestionIndex - 1]?.questionId;
      }
    },
    checkAnswer(SelectedAnswer) {
      if (SelectedAnswer.length > 0) {
        return SelectedAnswer.every((element) => element.isCorrect);
      } else {
        return SelectedAnswer.isCorrect;
      }
    },
    async getQuiz() {
      Object.assign(this.$data, initialState());

      try {
        const response = await quizesApi.getQuize(this.quizData1._id);
        if (response.data) {
          this.quizData = response.data;
          this.checkUserTakeQuiz();
        }
      } catch (error) {
        console.error("Error fetching quiz:", error);
      }
    },
    async geToNextQuestion(index) {
      try {
        const response = await questionsApi.getquestion(this.quizData.questions[index].questionId);
        if (response.data) {
          this.quizData.questions[index].questionId = response.data;
        }
      } catch (error) {
        console.error("Error fetching question:", error);
      }
    },
    async geToNextItem() {
      const Correct = this.checkAnswer(this.SelectedAnswer);

      if (Correct) {
        this.QuezGrad += (this.selectedQuestion.grad === 0)
          ? this.quizData.grad / this.quizData.questions?.length
          : this.selectedQuestion.grad;

        this.SelectedAnswer = undefined;
        this.currentQuestionIndex++;
      } else {
        this.SelectedAnswer = undefined;
        this.currentQuestionIndex++;
      }
    },
    compliteQuiz() {
      const Correct = this.checkAnswer(this.SelectedAnswer);

      if (Correct) {
        this.QuezGrad += (this.selectedQuestion.grad === 0)
          ? this.quizData.grad / this.quizData.questions?.length
          : this.selectedQuestion.grad;
      } else {
        this.SelectedAnswer = undefined;
      }

      userApi.updateMyQuiz({
        quiz: this.quizData._id,
        grade: this.QuezGrad,
      })
        .then((response) => {
          if (response.data) {
            localStorage.setItem("user", JSON.stringify(response.data.data));
            this.userData = JSON.parse(localStorage.getItem("user"));
            this.SelectedAnswer = undefined;
            this.quizCompleted = true;

            this.$toast({
              component: ToastificationContent,
              props: {
                title: "You have completed this Quiz",
                icon: "EditIcon",
                variant: "success",
              },
            });
          }
        })
        .catch((error) => {
          console.error("Error updating quiz:", error);
        });
    },
    retakeQuiz() {
      this.SelectedAnswer = undefined;
      this.currentQuestionIndex = 1;
      this.QuezGrad = 0;
      this.quizCompleted = false;
      this.selectedQuestion = this.quizData?.questions[this.currentQuestionIndex - 1]?.questionId;
    },
    geToPreviosItem() {
      this.currentQuestionIndex--;
    },
  },
};
</script>

<style lang="scss" scoped>
.form_wrapper {
  background-image: url("~@/assets/images/bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
  max-width: 100%;
  min-height: 90vh;
}

.question_title h4 {
  width: 100%;
  font-size: 2.359375rem;
  padding: 2rem 4rem;
  margin: 0;
  background: -webkit-gradient(linear,
      left top,
      right top,
      from(#c82090),
      to(#6a14d1));
  border-radius: 25px !important;

  background: linear-gradient(to right, #c82090, #6a14d1);
}

@media (max-width: 901px) {
  .question_title h4 {
    width: 100%;
    font-size: 1.9rem;
    padding: 0rem 0rem;
    margin: 0 0rem;
    background: -webkit-gradient(linear,
        left top,
        right top,
        from(#c82090),
        to(#6a14d1));
    padding: 9px;
    margin: 0 0rem;
    border-radius: 12px !important;
    background: linear-gradient(to right, #c82090, #6a14d1);
  }
}

.answer-lable {
  width: 100%;
  color: #0d1424;
  padding: 10px;
  font-size: 1.81125rem;
  padding-left: 3px;
  border: 3px solid #0d1424;
  cursor: pointer;
  border-radius: 10px !important;
  background-color: #ffffff;
}

.custom-control-info {
  // height: 22px !important;

}

.answer-lable-active {
  color: #ffffff;
  background: -webkit-gradient(linear,
      left top,
      right top,
      from(#c82090),
      to(#6a14d1));
  background: linear-gradient(to right, #c82090, #6a14d1);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  border: none;
}

.answer-lable-active h6 {
  color: #ffffff !important;

  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  border: none;
}

.Complete-tit h3 {
  color: #1b1b1b;
  font-size: 2.625rem;
}
</style>
